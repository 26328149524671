<template>
    <div class="card full-height bg-ligth">
      <div class="card-header">
        <h3 class="mb-0">{{ lista_campos != "" ? "Editar" : "Criar" }} Tipo Protocolo</h3>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <div class="form">
            <div class="row form-group">
          
  
              <div class="row form-group col-md-12">
                <div class="col-md-6">
                  <label for="porcentagem">Nome:</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.nome"
                    placeholder="Digite o tipo do Protocolo"
                  />
                </div>
                <div class="col-md-12">
                  <label for="porcentagem">Descrição:</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.descricao"
                    placeholder="Digite a Descrição"
                  />
                </div>
            </div>
          </div>
        </div>
  
        <div class="row justify-content-end">
          <div class="col-md-12 text-center">
            <v-btn
              color="primary"
              elevation="2"
              :loading="verif"
              @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
              class="btn btn-primary"
              :disabled="verif"
              >{{ lista_campos == "" ? "cria" : "edita" }}</v-btn
            >
          </div>
        </div>
  
        <!-- </div> -->
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
  import { fireAlert } from "@/core/mixins/alertMessageMixin";
  
  export default {
    mixins: [searchAddressMixin, fireAlert],
    data() {
      return {
        verif: false,
  
        form: {
          nome: null,
          descricao:null,
        },
    
  
        value: "",
        loading: false,
        cpf_cnpj1: null,
        saldo: null,
      };
    },
  
    computed: {
  
      mensagem_alert() {
        return this.$store.state.tipoProtocolo.mensagem_alert;
      },
  
      lista_campos() {
        return this.$store.state.configEmpresa.lista_campos;
      },
    },
    created() {
      this.preenxerCampor();
    },
    watch: {
       
  
    },
    methods: {
      async confirm(tipo) {
        await this.$confirm({
          title: "Tem Certeza?",
          message: `Esta ação  ` + tipo + ` uma Tipo Protocolo para o cliente no sistema?`,
          button: {
            no: "No",
            yes: "Si",
          },
          callback: (confirm) => {
            if (confirm) {
              if (tipo == "cria") this.create();
              if (tipo == "edita") this.update();
            }
          },
        });
      },
      async create() {
        this.verif = true;
        await this.$store.dispatch("tipoProtocolo/create_tipo_protocolo", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
          routeName: "tipoProtocolo",
        });
      },
 
      async update() {
        this.verif = true;
        await this.$store.dispatch("tipoProtocolo/update_tipo_protocolo", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
          routeName: "tipoProtocolo",
        });
      },
      async preenxerCampor() {
  
        if (this.lista_campos != "")
          this.form = {
            id: this.lista_campos.id,
            nome: this.lista_campos.nome,
            descricao: this.lista_campos.descricao,
  
          };
   
      },
    },
  };
  </script>
  
  <style scoped>
  .lista:hover {
    background-color: rgba(167, 183, 255, 0.705);
  }
  </style>